/**
 * @generated SignedSource<<5f51b5805f590b3398438beb4a232fdb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HeroContentBlock_contentBlock$data = {
  readonly __typename: "Button";
  readonly " $fragmentSpreads": FragmentRefs<"HeroButton_contentBlock">;
  readonly " $fragmentType": "HeroContentBlock_contentBlock";
} | {
  readonly __typename: "HeroBrandLogoBlock";
  readonly " $fragmentSpreads": FragmentRefs<"HeroBrandLogo_contentBlock">;
  readonly " $fragmentType": "HeroContentBlock_contentBlock";
} | {
  readonly __typename: "HeroCountdownBlock";
  readonly " $fragmentSpreads": FragmentRefs<"HeroCountdown_contentBlock">;
  readonly " $fragmentType": "HeroContentBlock_contentBlock";
} | {
  readonly __typename: "HeroDepositFormBlock";
  readonly " $fragmentSpreads": FragmentRefs<"HeroDepositForm_contentBlock">;
  readonly " $fragmentType": "HeroContentBlock_contentBlock";
} | {
  readonly __typename: "HeroLogoBlock";
  readonly " $fragmentSpreads": FragmentRefs<"HeroLogo_contentBlock">;
  readonly " $fragmentType": "HeroContentBlock_contentBlock";
} | {
  readonly __typename: "HeroMultipleOffersBlock";
  readonly " $fragmentSpreads": FragmentRefs<"HeroMultipleOffers_contentBlock">;
  readonly " $fragmentType": "HeroContentBlock_contentBlock";
} | {
  readonly __typename: "HeroRichTextBlock";
  readonly " $fragmentSpreads": FragmentRefs<"HeroRichText_contentBlock">;
  readonly " $fragmentType": "HeroContentBlock_contentBlock";
} | {
  readonly __typename: "HeroSingleLineTextBlock";
  readonly " $fragmentSpreads": FragmentRefs<"HeroSingleLineText_contentBlock">;
  readonly " $fragmentType": "HeroContentBlock_contentBlock";
} | {
  readonly __typename: "HeroSpacingBlock";
  readonly " $fragmentSpreads": FragmentRefs<"HeroSpacing_contentBlock">;
  readonly " $fragmentType": "HeroContentBlock_contentBlock";
} | {
  readonly __typename: "Link";
  readonly " $fragmentSpreads": FragmentRefs<"HeroLink_contentBlock">;
  readonly " $fragmentType": "HeroContentBlock_contentBlock";
} | {
  readonly __typename: "ProgramGroupBlock";
  readonly " $fragmentSpreads": FragmentRefs<"ProgramGroupBlock_block">;
  readonly " $fragmentType": "HeroContentBlock_contentBlock";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "HeroContentBlock_contentBlock";
};
export type HeroContentBlock_contentBlock$key = {
  readonly " $data"?: HeroContentBlock_contentBlock$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeroContentBlock_contentBlock">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeroContentBlock_contentBlock",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HeroLink_contentBlock"
        }
      ],
      "type": "Link",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HeroButton_contentBlock"
        }
      ],
      "type": "Button",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProgramGroupBlock_block"
        }
      ],
      "type": "ProgramGroupBlock",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HeroDepositForm_contentBlock"
        }
      ],
      "type": "HeroDepositFormBlock",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HeroLogo_contentBlock"
        }
      ],
      "type": "HeroLogoBlock",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HeroSpacing_contentBlock"
        }
      ],
      "type": "HeroSpacingBlock",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HeroBrandLogo_contentBlock"
        }
      ],
      "type": "HeroBrandLogoBlock",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HeroMultipleOffers_contentBlock"
        }
      ],
      "type": "HeroMultipleOffersBlock",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HeroRichText_contentBlock"
        }
      ],
      "type": "HeroRichTextBlock",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HeroSingleLineText_contentBlock"
        }
      ],
      "type": "HeroSingleLineTextBlock",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HeroCountdown_contentBlock"
        }
      ],
      "type": "HeroCountdownBlock",
      "abstractKey": null
    }
  ],
  "type": "HeroContentBlock",
  "abstractKey": "__isHeroContentBlock"
};

(node as any).hash = "83d4cc67e1714d9ea16a7d5db8a665c2";

export default node;
