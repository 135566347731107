import styled from '@emotion/styled';
import { Color } from '@pafcloud/style';
import { HeroColors } from '../hero-colors';

export const HeroBox = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  position: 'relative',
  margin: 'auto',
  padding: 24,
  minWidth: 'min(480px, 100%)',
  maxWidth: 'fit-content',
  borderRadius: Color.CornerRadius.Base,
  background: HeroColors.BoxBackground,

  p: {
    color: HeroColors.BoxTextOverride,
  },
});
