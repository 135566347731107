/**
 * @generated SignedSource<<94d59a4f9541ae6b246320304c43ba95>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type HeroContentAlignment = "CENTER" | "LEFT";
import { FragmentRefs } from "relay-runtime";
export type BasicHero_data$data = {
  readonly contentBlocks: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"HeroContentBlock_contentBlocks">;
  }>;
  readonly heroContentAlignment: HeroContentAlignment;
  readonly useSolidColorBackground: boolean;
  readonly " $fragmentType": "BasicHero_data";
};
export type BasicHero_data$key = {
  readonly " $data"?: BasicHero_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"BasicHero_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BasicHero_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "useSolidColorBackground",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "heroContentAlignment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "contentBlocks",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HeroContentBlock_contentBlocks"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BasicHero",
  "abstractKey": null
};

(node as any).hash = "162ba12b46cc4500cd3ed6f74252f1e0";

export default node;
