import styled from '@emotion/styled';
import type { FC } from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import { Stack } from '@pafcloud/base-components';
import type { BasicHero_data$key } from './__generated__/BasicHero_data.graphql';
import { HeroBox } from './hero-box';
import { HeroContentBlocks } from './hero-content-blocks/HeroContentBlock';

const dataFragment = graphql`
  fragment BasicHero_data on BasicHero {
    useSolidColorBackground
    heroContentAlignment

    contentBlocks {
      ...HeroContentBlock_contentBlocks
    }
  }
`;

const BasicHeroContent = styled(Stack)({
  display: 'grid',
  gridAutoColumns: '1fr',

  // Content is Center by default
  textAlign: 'center',
  justifyContent: 'center',
  justifyItems: 'center',
  '--object-position': 'center center',

  '&[data-alignment="LEFT"]': {
    textAlign: 'left',
    justifyContent: 'start',
    justifyItems: 'start',
    '--object-position': 'left center',
  },
});

type BasicHeroComponentProps = {
  data: BasicHero_data$key;
  onHeroClick: () => void;
  loading?: 'lazy' | 'eager';
};

const BasicHero: FC<BasicHeroComponentProps> = ({ data, onHeroClick, loading }) => {
  const { useSolidColorBackground, heroContentAlignment, contentBlocks } = useFragment(dataFragment, data);

  if (useSolidColorBackground) {
    return (
      <BasicHeroContent data-alignment={heroContentAlignment} space={2}>
        <HeroBox>
          <HeroContentBlocks contentBlocks={contentBlocks} onHeroClick={onHeroClick} loading={loading} />
        </HeroBox>
      </BasicHeroContent>
    );
  }

  return (
    <BasicHeroContent data-alignment={heroContentAlignment} space={2}>
      <HeroContentBlocks contentBlocks={contentBlocks} onHeroClick={onHeroClick} loading={loading} />
    </BasicHeroContent>
  );
};

export default BasicHero;
